import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams, NavLink } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import "./GardenList.css";
import gardenPic from "../assets/garden-pic.png";
import { WateringMapper } from "../utils/WateringMapper";
import { Modal, notification, Switch, Button, Select, Input, Popconfirm, Slider, InputNumber, Tooltip, DatePicker, Checkbox, Spin } from "antd";

// icons
import { CheckOutlined, RightOutlined, SyncOutlined, DeleteOutlined, ZoomInOutlined, ArrowUpOutlined, ArrowDownOutlined, CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { BiDroplet, BiLoaderAlt, BiBluetooth, BiInfoCircle } from "react-icons/bi";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { RxDragHandleDots2 } from "react-icons/rx";

import "./Scheduler.css";
import { BASE_MAPS, MAP_ATTRIBUTION_URL } from "../utils/MapUtils";
import {
  createPlanRequestBuilder,
  deletePlanRequestBuilder,
  transformDevicesToResourcesArray,
  transformPlanResourceToEvent,
} from "../utils/SchedulerUtil";
import SchedulerMap from "../components/SchedulerMap";
import SchedulerGardenInformation from "../components/SchedulerGardenInformation";
import CheckSpot from "../components/CheckSpot";
import ActiveDaySelection from "../components/ActiveDaySelection";
import axios from "axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import EventScheduler from "../components/EventScheduler/EventScheduler";
import tr from '../i18n'
import useWindowSize from "../utils/WindowSize"
import dayjs from 'dayjs'
import { calculateBatteryLevel, getSignalStrengthForBars } from '../utils/DeviceUtils'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { AppContext } from '../AppContext'
import moment from 'moment'
import SignalStrengthBars from "../components/SignalStrengthBars";
import InfoLabel from "../components/InfoLable";
import { ROLES, hasAccess } from "../utils/AccessLevels";
dayjs.extend(customParseFormat)

const Scheduler = () => {
  const [events, setEvents] = useState([]);
  const [resources, setResources] = useState([]);
  const [mapCenter, setMapCenter] = useState({});
  const [selectedDays, setSelectedDays] = useState([0, 1, 2, 3, 4, 5, 6]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDay, setSelectedDay] = useState(new Date().getDay());
  const { id: gardenId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient()
  const { width } = useWindowSize()
  const [optimization, setOptimization] = useState({
    sun: false,
    rain: false,
    wind: false,
  })
  const { notifications, fetchAllGardens, user, role, tenantSettings } = useContext(AppContext)
  const { RangePicker } = DatePicker
  const [isWateringFlowEventsExpanded, setIsWateringFlowEventsExpanded] = useState(false);
  /**
   * Quick Fix: scheduler doesn't show the plans after a component unmount
   * 
   * Force refreshing the page
   */
  useEffect(() => {
    if (!localStorage.getItem('init_load')) {
      localStorage.setItem('init_load', '1')
      // window.location.reload()
    }
    return () => {
      localStorage.removeItem('init_load')
    }
  }, [])

  /**
   * Queries reset on unmount
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    return () => {
      queryClient.removeQueries('Garden', { exact: true })
      queryClient.removeQueries('GardenDevices', { exact: true })
      queryClient.removeQueries('GardenDevicePlansPerDay', { exact: true })
    }
  }, [])

  const garden = useQuery("Garden", () =>
    axios.get(
      process.env.REACT_APP_PLANNER_API_END_POINT +
      "/garden/" +
      gardenId
    )
  );

  useEffect(() => {
    if (!garden.data) return;

    setMapCenter(garden.data.data.garden.geoJson)
    setSelectedDays(garden.data.data.garden.wateringDays)
    setIsWateringStatusActive(garden.data.data.garden.wateringStatus === 'active')
    setCellDuration(garden.data.data.garden.schedulerCellDuration)

    if (garden.data.data.garden.optimization) {
      // console.log('garden.data.data.garden', garden.data.data.garden);
      setOptimization(garden.data.data.garden.optimization)
    }
  }, [garden.data])



  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [schedulerMode, setSchedulerMode] = useState(null)

  const config = {
    onTimeRangeSelected: async (args) => {
      const planData = createPlanRequestBuilder(args, selectedDay, `Advanced plan (${schedulerMode})`)
      setUnCommittedRequests(prev => [...prev, { data: planData, type: 'create_plan', schedulerMode, id: args.id }])
      setEvents(prev => [...prev, { ...args, status: 'new' }])
      // we need to manage new events per day in order to persist through day-tab changes
      setDayEvents(p => {
        if (p[selectedDay]) {
          p[selectedDay].push({ ...args, status: 'new' })
        } else {
          p[selectedDay] = [{ ...args, status: 'new' }]
        }

        return p
      })
    },
    onEventDeleted: (args) => {
      console.log(args, 'args');
      if (args.e.data.status === 'new') {
        // if this is a new event remove it from the uncommitted requests

        setUnCommittedRequests(prev => {
          console.log(prev);
          const filtered = prev.filter(e => e.id !== args.e.data.id)
          console.log(filtered);
          return filtered;
        })
      } else {
        const planData = deletePlanRequestBuilder(args)
        setUnCommittedRequests(prev => [...prev, { data: planData, type: 'delete_plan', schedulerMode }])
      }
      setEvents(prev => prev.map(e => {
        if (e.id === args.e.data.id) {
          return {
            ...e,
            status: 'deleted' // not compatible with Daypilot
          }
        }
        return e
      }))
      setDayEvents(p => {
        if (p[selectedDay]) {
          p[selectedDay] = p[selectedDay].map(e => {
            if (e.id === args.e.data.id) {
              return {
                ...e,
                status: 'deleted' // not compatible with Daypilot
              }
            }
            return e
          })
        }

        return p
      })
    },
  };

  const week = [
    {
      day: "Pazartesi",
      id: 1,
    },
    {
      day: "Salı",
      id: 2,
    },
    {
      day: "Çarşamba",
      id: 3,
    },
    {
      day: "Perşembe",
      id: 4,
    },
    {
      day: "Cuma",
      id: 5,
    },
    {
      day: "Cumartesi",
      id: 6,
    },
    {
      day: "Pazar",
      id: 0,
    },
  ];

  /**
   * Template selection logic
   */
  const [creatingTemplate, setCreatingTemplate] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [activeTemplates, setActiveTemplates] = useState([]);

  const [templateHours, setTemplateHours] = useState(null);
  const [templateMinutes, setTemplateMinutes] = useState(null);
  const [templateDuration, setTemplateDuration] = useState(null);
  const [templateActiveDays, setTemplateActiveDays] = useState([1, 2, 3, 4, 5, 6, 0]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isDefaultTemplate, setIsDefaultTemplate] = useState(false);

  const quickPlanTemplates = useQuery('QuickPlanTemplates', () => {
    return axios.get(
      process.env.REACT_APP_PLANNER_API_END_POINT +
      "/quick-plan-templates?gardenId=" +
      gardenId
    );
  })

  const gardenActiveTemplates = useQuery('GardenActiveTemplates', () => {
    return axios.get(
      process.env.REACT_APP_PLANNER_API_END_POINT +
      "/garden/" +
      gardenId +
      "/active-templates"
    )
  })

  useEffect(() => {
    if (!quickPlanTemplates.data) return;

    setTemplates(quickPlanTemplates.data.data.templates);
  }, [quickPlanTemplates.data])

  useEffect(() => {
    if (!gardenActiveTemplates.data) return;

    setActiveTemplates(gardenActiveTemplates.data.data.templates);
    setSelectedTemplates(gardenActiveTemplates.data.data.templates);
  }, [gardenActiveTemplates.data])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!selectedTemplates) {
      return;
    }

    // when selected templates are different from the active templates, we should prompt to save them
    if (
      !(
        selectedTemplates.every((t) =>
          activeTemplates.map((a) => a._id).includes(t._id)
        ) && selectedTemplates.length === activeTemplates.length
      )
    ) {
      setHasUnsavedChanges(true);
      notification.info({
        description: (
          <div className="flex gap-4 pr-6 items-center">
            <div>{tr("There are unsaved changes to the watering schedule")}</div>
            <Button
              size="small"
              type="primary"
              style={{ borderRadius: 5 }}
              onClick={handleSaveChanges}
            >
              {tr("Save")}
            </Button>
          </div>
        ),
        duration: null,
        key: "templates-unsaved",
      });
    }
  }, [selectedTemplates]);

  const onTemplateClick = (temp) => {
    console.log(selectedTemplates, temp);
    if (selectedTemplates.some((s) => s._id === temp._id)) {
      setSelectedTemplates((prev) => {
        const remaining = prev.filter((s) => s._id !== temp._id)
        console.log('removing temp', remaining);
        return remaining
      });
    } else {
      console.log('adding temp', temp);
      setSelectedTemplates((prev) => [...prev, temp]);
    }
  };

  const deleteTemplate = useMutation((templateId) => {
    return axios.delete(
      `${process.env.REACT_APP_PLANNER_API_END_POINT}/quick-plan-templates/${templateId}`
    )
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(['QuickPlanTemplates', 'GardenActiveTemplates'])
    },
    onError: (err) => {
      console.log(err.response.data);

      // get error message from the response and show in description
      notification.error({
        message: tr("Error"),
        description: err.response.data.error,
      });
    }
  })

  const onTemplateDelete = async (e, t) => {
    e.stopPropagation()


    const remainingTemp = selectedTemplates.filter(st => st._id !== t._id)
    await updateActiveTemplates.mutateAsync(remainingTemp.map(t => t._id))

    await deleteTemplate.mutateAsync(t._id)
    setSelectedTemplates(remainingTemp)
    // setActiveTemplates(p => p.filter(at => at._id !== t._id))
    setTemplates(p => p.filter(tm => tm._id !== t._id))
  }

  const isActiveTemplate = (temp) =>
    selectedTemplates && selectedTemplates.some((s) => s._id === temp._id);

  const getActiveTemplateClass = (temp) => {
    if (isActiveTemplate(temp) && temp.type === "custom") {
      return "border-green-brand";
    } else if (!isActiveTemplate(temp) && temp.type === "default") {
      return "border-blue-500"
    } else if (isActiveTemplate(temp) && temp.type === "default") {
      return "border-blue-500"
    }

    return "";
  };

  const onTemplateCreate = async (e, type = 'custom') => {
    try {
      if (isDefaultTemplate) type = 'default';

      if (type === 'custom' || type === 'default') {
        if (
          !templateHours ||
          !templateMinutes ||
          templateDuration < 1 ||
          templateActiveDays.length === 0
        ) {
          return;
        }
        setCreatingTemplate(true);
        const res = await axios.post(
          process.env.REACT_APP_PLANNER_API_END_POINT + "/quick-plan-templates",
          {
            startTime: templateHours + ":" + templateMinutes,
            duration: templateDuration,
            activeDays: templateActiveDays,
            ...(isDefaultTemplate ? {} : { gardenId }), // default template will be global for tenant
            type
          }
        );
        setTemplates((prev) => [...prev, res.data.template]);
        setTemplateActiveDays([1, 2, 3, 4, 5, 6, 0]);
        setIsDefaultTemplate(false)

      } else if (type === 'custom_multiple') {
        if (startTimes.length === 0 ||
          multipleTempDurations.every(d => d === 0)) {
          return
        }

        setCreatingTemplate(true);

        const res = await axios.post(
          process.env.REACT_APP_PLANNER_API_END_POINT + "/quick-plan-templates",
          {
            startTimes: startTimes.map(s => ({
              day: s.day,
              hour: s.hour,
              minute: s.minute
            })),
            durations: multipleTempDurations,
            gardenId,
            type
          }
        );

        setTemplates((prev) => [...prev, res.data.template]);
      }

    } catch (error) {
      console.log(error);
    } finally {
      setCreatingTemplate(false);
    }
  };

  const updateActiveTemplates = useMutation((templateIds) => {
    console.log('update active', templateIds);
    return axios.put(
      process.env.REACT_APP_PLANNER_API_END_POINT +
      "/garden/active-templates",
      {
        gardenId,
        templateIds,
      }
    )
  }, {
    onSuccess: () => {
      notification.success({
        message: tr("Changes saved successfully"),
        placement: "topRight",
      });
      queryClient.invalidateQueries('Garden')
    },
    onError: () => {
      notification.error({
        message: tr("Something went wrong while saving changes"),
        placement: "topRight",
      });
    },
    onSettled: () => {
      setHasUnsavedChanges(false);
      notification.close("templates-unsaved");
      queryClient.invalidateQueries('GardenActiveTemplates')
    }
  })

  const handleSaveChanges = () => {
    notification.close("templates-unsaved");
    const templateIds = selectedTemplates.map(t => t._id)
    updateActiveTemplates.mutate(templateIds)
  };

  const activeDaysMap = [
    { name: "P", id: 1 },
    { name: "S", id: 2 },
    { name: "Ç", id: 3 },
    { name: "P", id: 4 },
    { name: "C", id: 5 },
    { name: "C", id: 6 },
    { name: "P", id: 0 },
  ];

  const pad = (d) => {
    return d < 10 ? "0" + d.toString() : d.toString();
  };

  const generateMinutesArray = () => {
    const arr = []
    for (let i = 0; i < 60; i++) {
      arr.push([pad(i)])
    }
    return arr
  }

  const generateHoursArray = () => {
    const arr = []
    for (let i = 0; i < 24; i++) {
      arr.push([pad(i)])
    }
    return arr
  }

  const days = [
    {
      id: 1,
      name: tr("Mo"),
    },
    {
      id: 2,
      name: tr("Tu"),
    },
    {
      id: 3,
      name: tr("We"),
    },
    {
      id: 4,
      name: tr("Th"),
    },
    {
      id: 5,
      name: tr("Fr"),
    },
    {
      id: 6,
      name: tr("Sa"),
    },
    {
      id: 0,
      name: tr("Su"),
    },
  ]

  const calculateEndTime = (t) => {
    let totalDuration = 0;

    // console.log(gardenDevices.data);
    if (!gardenDevices.data) return { endTime: '00:00', totalDuration };

    const { startTime, duration } = t

    // converting start time to minutes
    let mins = startTime.split(':')
      .map((p, i) => i === 0 ? parseInt(p) * 60 : parseInt(p))
      .reduce((p, c) => p += c, 0)

    gardenDevices.data.data.devices.forEach(d => {
      if (!d.Device) return;

      const valves = JSON.parse(d.Device.valves);
      Object.values(valves).filter(v => v.state === 'on').forEach(v => {
        mins += duration;
        totalDuration += duration;
      })
    })

    let notInSameDay = false;
    let endHours = Math.floor(mins / 60)
    const endMins = Math.floor(mins % 60)

    /**
     * if endHours is greater than 24, 
     * it means the end time is in the next day.
     * Mod 24 to get the actual hour
     */
    notInSameDay = endHours > 24
    endHours = endHours % 24

    const durationHours = Math.floor(totalDuration / 60)
    const durationMins = Math.floor(totalDuration % 60)

    return {
      endTime: `${pad(endHours > 24 ? endHours - 24 : endHours)}:${pad(endMins)}`,
      totalDuration: {
        d: Math.floor(totalDuration / 1440),
        h: durationHours,
        m: durationMins
      },
      notInSameDay
    }
  }

  const generateTextForNotInSameDay = (t) => {
    const { totalDuration } = calculateEndTime(t)
    const { d: days } = totalDuration
    if (days === 0) {
      return 'Sonraki gün' //'Next day'
    }

    if (days > 0) {
      return `+${days * 24} hours`
    }

    return ''
  }

  const generateTemplateCard = (t) => (
    <div
      onClick={() => onTemplateClick(t)}
      className={`w-full md:w-auto relative flex flex-col bg-white border rounded-md text-gray-600 cursor-pointer ${getActiveTemplateClass(
        t
      )}`}
    >
      {isActiveTemplate(t) && (
        <div className="absolute top-2 left-2 flex rounded-full bg-green-brand w-5 h-5 items-center justify-center text-white border border-green-brand">
          <CheckOutlined />
        </div>
      )}
      {(t.type === 'custom') && <div onClick={(e) => onTemplateDelete(e, t)} className="absolute top-1 right-1 flex items-center justify-center w-5 h-5 rounded-sm bg-gray-200 text-xs hover:bg-gray-500 hover:text-white">
        <DeleteOutlined />
      </div>}
      {(t.type === 'default' && hasAccess(ROLES.TECHNICAL_ADMIN, role)) && <div onClick={(e) => onTemplateDelete(e, t)} className="absolute top-1 right-1 flex items-center justify-center w-5 h-5 rounded-sm bg-gray-200 text-xs hover:bg-gray-500 hover:text-white">
        <DeleteOutlined />
      </div>}
      <div className="flex border-b">
        <div className="flex flex-col justify-center border-r">
          <div className="flex flex-col pt-2 px-6 ">
            <div className="text-xs text-gray-500">{tr('Start')}</div>
            <div className="text-xl font-semibold">{t.startTime}</div>
          </div>
          <div className="flex flex-col pb-2 px-6 ">
            <div className="text-xs text-gray-500">{tr('End')}</div>
            <div className="text-xl font-semibold">
              {calculateEndTime(t).endTime}
              {calculateEndTime(t).notInSameDay && <div className=" text-xs p-1 rounded-md bg-gray-100 flex flex-nowrap font-normal">
                {generateTextForNotInSameDay(t)}
              </div>}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center text-3xl font-semibold w-full">
          <div className="flex flex-col p-4 py-3 w-full items-start">
            <div>
              <div className=" text-gray-500 text-xs">{tr('Zone')}</div>
              {t.duration}d
            </div>
          </div>
          <div className="flex flex-col border-t p-4 py-3 w-full items-start">
            <div>
              <div className=" text-gray-500 text-xs">{tr("Total")}</div>
              {calculateEndTime(t).totalDuration.h > 0 && calculateEndTime(t).totalDuration.h + 's'} {calculateEndTime(t).totalDuration.m > 0 && calculateEndTime(t).totalDuration.m + 'd'}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col p-3 px-6">
        <div className="text-xs text-gray-500">{tr('Active days')}</div>
        <div className="text-xl font-semibold flex gap-2">
          {activeDaysMap.map((a) => (
            <div
              className={t.activeDays.includes(a.id) ? "text-green-brand" : ""}
            >
              {a.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  /**
   * Garden devices
   */
  const gardenDevices = useQuery("GardenDevices", () =>
    axios.get(
      process.env.REACT_APP_PLANNER_API_END_POINT +
      "/garden/" +
      gardenId +
      "/device"
    )
  );

  /**
   * Garden plans
   */
  const plans = useQuery(
    ["GardenDevicePlansPerDay", selectedDay, schedulerMode],
    async () => {
      if (!gardenDevices.data?.data?.devices) return [];

      const devicePlans = await Promise.all(
        gardenDevices.data.data.devices.map(async (device) => {
          const days = schedulerMode === schedulerModes.ADVANCED_COMMON ? selectedDays[0] : [selectedDay];
          const res = await axios.get(
            `${process.env.REACT_APP_PLANNER_API_END_POINT}/plan?deviceSerial=${device.deviceSerial}&days=${days}&bypassTemp=true`
          );
          return {
            plans: res.data.plans?.plans[0]?.times || [],
            serial: device.deviceSerial,
            model: device.model
          };
        })
      );

      return devicePlans;
    },
    { enabled: !!gardenDevices.data }
  );

  // Add this new query to fetch watering flow events
  const wateringFlowEvents = useQuery(
    ["WateringFlowEvents", gardenId],
    () => axios.get(`${process.env.REACT_APP_PLANNER_API_END_POINT}/garden/${gardenId}/watering-flow-events`),
    {
      enabled: !!gardenId,
    }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!plans.data) return;

    if (!isAdvancedFeaturesVisible()) return;

    let events = [];
    console.log('plans.data', plans.data)
    plans.data.forEach(p => {
      const e = transformPlanResourceToEvent(p.plans, { serial: p.serial, model: p.model })
      events = [...events, ...e]
    });

    // restore day events if there are any
    events = [...events, ...(dayEvents[selectedDay] || [])]

    // Add watering flow events if available
    if (wateringFlowEvents.isSuccess && wateringFlowEvents.data?.data?.data) {
      const flowEvents = Object.entries(wateringFlowEvents.data.data.data).flatMap(([deviceSerial, deviceData]) =>
        Object.entries(deviceData.result).flatMap(([valve, valveEvents]) =>
          valveEvents.map(event => {
            // Create moment objects with the exact UTC date and time
            const startMoment = moment(event.start.slice(0, -6))
            const endMoment = moment(event.end.slice(0, -6))

            const flowEvent = {
              id: `${deviceSerial}_${valve}_${event.start}`,
              text: `${event.flow} ${event.flowUnits || 'liters'}`,
              start: startMoment.format('YYYY-MM-DDTHH:mm:ss'),
              end: endMoment.format('YYYY-MM-DDTHH:mm:ss'),
              resource: `${deviceSerial}_${valve}_watering`,
              barColor: "#4CAF50",
              barBackColor: "#A5D6A7",
              status: 'flow',
              data: { ...event, valve, deviceSerial }
            };

            // Find the closest event in the serial_valve resource
            const closestEvent = events.find(e => {
              if (e.resource !== `${deviceSerial}_${valve}`) return false;

              const startDiff = Math.abs(moment(e.start).diff(startMoment, 'minutes'));
              const endDiff = Math.abs(moment(e.end).diff(endMoment, 'minutes'));

              return startDiff <= 3 && endDiff <= 5; // Allow for some flexibility in start and end times
            });

            if (closestEvent) {
              closestEvent.text = `${flowEvent.text}`;
            }

            return flowEvent;
          })
        )
      );
      events = [...events, ...flowEvents];
    }

    setEvents(events)
  }, [plans.data, schedulerMode, wateringFlowEvents.data, selectedDay]);

  // pins for devices
  const [pins, setPins] = useState([])
  const [gardenDevicesList, setGardenDevicesList] = useState([])

  useEffect(() => {
    if (!gardenDevices.data) return;

    setGardenDevicesList(gardenDevices.data.data.devices)

    const pinsList = gardenDevices.data.data.devices.map(d => ({
      pin: [d.Device.lat || 0, d.Device.lng || 0],
      type: d.model === "BL" ? "bl" : d.alive,
      name: d.model === "BL" ? d.sid : d.Device.name,
      model: d.model
    }))
    setPins(pinsList)

    setResources(
      transformDevicesToResourcesArray(gardenDevices.data.data.devices)
    );
  }, [gardenDevices.data]);


  /**
   * Manage scheduler mode
   */
  const [isSwitchingToAdvanced, setIsSwitchingToAdvanced] = useState(false)
  const [isSwitchingToAdvancedCommon, setIsSwitchingToAdvancedCommon] = useState(false)
  const [isSwitchingToQuick, setIsSwitchingToQuick] = useState(false)

  const schedulerModes = {
    QUICK: 'quick',
    ADVANCED_COMMON: 'advanced_common',
    ADVANCED_PER_DAY: 'advanced_per_day',
  }

  // mutations
  const updateSchedulerMode = useMutation(({ schedulerMode }) => {
    return axios.put(
      process.env.REACT_APP_PLANNER_API_END_POINT +
      "/garden",
      {
        id: gardenId,
        schedulerMode,
      }
    )
  })

  const commitSchedules = useMutation(({ commits }) => {
    return axios.post(
      process.env.REACT_APP_PLANNER_API_END_POINT +
      "/garden/commit-schedules",
      {
        gardenId,
        commits,
      }
    )
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('GardenDevicePlansPerDay')
      queryClient.invalidateQueries('Garden')
    }
  })

  useEffect(() => {
    if (!garden.data) return;

    setSchedulerMode(garden.data.data.garden.schedulerMode)
  }, [garden.data])

  const isAdvancedFeaturesVisible = () => (schedulerMode === schedulerModes.ADVANCED_COMMON || schedulerMode === schedulerModes.ADVANCED_PER_DAY)

  const onSchedulerPrimaryModeChange = async (e) => {
    if (e) {
      setIsSwitchingToAdvanced(true)
    } else {
      setIsSwitchingToQuick(true)
    }
  }

  const onSchedulerSecondaryModeChange = async (e) => {
    if (e) {
      await updateSchedulerMode.mutateAsync({
        schedulerMode: schedulerModes.ADVANCED_PER_DAY
      })
      setSchedulerMode(schedulerModes.ADVANCED_PER_DAY)
    } else {
      setIsSwitchingToAdvancedCommon(true)
    }
  }

  const onAdvancedConfirm = async () => {
    await updateSchedulerMode.mutateAsync({
      schedulerMode: schedulerModes.ADVANCED_COMMON
    })
    setSchedulerMode(schedulerModes.ADVANCED_COMMON)
    setIsSwitchingToAdvanced(false)
  }

  const onQuickConfirm = async () => {
    await updateSchedulerMode.mutateAsync({
      schedulerMode: schedulerModes.QUICK
    })
    setSchedulerMode(schedulerModes.QUICK)
    setIsSwitchingToQuick(false)
  }

  const onAdvancedCommonConfirm = async () => {
    await updateSchedulerMode.mutateAsync({
      schedulerMode: schedulerModes.ADVANCED_COMMON
    })
    setSchedulerMode(schedulerModes.ADVANCED_COMMON)
    setIsSwitchingToAdvancedCommon(false)
  }

  /**
   * Manage common scheduler
   */
  const onCommonSchedulerActiveDaysChange = (days) => {
    if (days.length < 1) return;

    setSelectedDays(days)
    setUnCommittedRequests(prev => [...prev, { data: { wateringDays: days }, type: 'update_watering_days', schedulerMode }])
  }


  /**
   * Manage save changes for scheduler
   */
  const [unCommittedRequests, setUnCommittedRequests] = useState([])

  const hasUnsavedSchedulerChanges = () => {
    return (unCommittedRequests.length > 0)
  }

  const handleSaveScheduleChanges = async () => {
    if (unCommittedRequests.length === 0) return;

    console.log('unCommittedRequests', unCommittedRequests);
    commitSchedules.mutate({ commits: unCommittedRequests })
    setUnCommittedRequests([])
    setDayEvents({})
  }

  /**
   * control scheduler cell duration
   */
  const initialCellDuration = 15
  const [cellDuration, setCellDuration] = useState(initialCellDuration)

  /**
   * Manage watering state
   */
  const [isWateringStatusActive, setIsWateringStatusActive] = useState(null)

  const wateringStatus = useMutation(({ status }) => {
    return axios.put(
      process.env.REACT_APP_PLANNER_API_END_POINT +
      "/garden",
      {
        id: gardenId,
        wateringStatus: status,
      }
    )
  }, {
    onSettled: () => {
      queryClient.invalidateQueries('Garden')
      fetchAllGardens({ background: true, force: true })
    }
  })

  const updateWateringStatus = (status) => {
    if (status === null) return;

    wateringStatus.mutate({
      status: status ? 'active' : 'disabled'
    })
  }

  /**
   * Manage per-day scheduler
   */
  const [dayEvents, setDayEvents] = useState({})
  const onWeekDaySelect = (d) => {
    queryClient.removeQueries('GardenDevicePlansPerDay', { exact: true })
    setEvents([])
    setSelectedDay(d)
  }

  const onPerDaySchedulerActiveDaysChange = (e, d) => {
    let days = selectedDays
    if (e) {
      days = [...days, d]
      setSelectedDays(days)
    } else {
      days = days.filter(sd => sd !== d)
      setSelectedDays(days)
    }
    setUnCommittedRequests(prev => [...prev, { data: { wateringDays: days }, type: 'update_watering_days', schedulerMode }])
  }

  // device status
  const getStatusIndicator = (status, mode) => {
    if (status === 'alive' && mode === 'test') {
      return <div className="relative p-1 rounded-full bg-green-500 ">
        <div className=" absolute p-1 top-0 left-0 rounded-full bg-green-500 animate-ping"></div>
      </div>
    }

    if (status === 'alive') {
      return <div className="relative p-1 rounded-full bg-blue-500 ">
        <div className=" absolute p-1 top-0 left-0 rounded-full bg-blue-500 animate-ping"></div>
      </div>
    }

    if (status === 'dead') {
      return <div className="relative p-1 rounded-full bg-red-500 ">
        <div className=" absolute p-1 top-0 left-0 rounded-full bg-red-500 "></div>
      </div>
    }

    return <div className="relative p-1 rounded-full bg-gray-500 ">
      <div className=" absolute p-1 top-0 left-0 rounded-full bg-gray-500 "></div>
    </div>
  }

  /**
   * Manage garden test mode
   */
  const MGMT_MODES = {
    BATTERY_SAVER: {
      id: 'battery_saver',
      label: tr('Battery Saver')
    },
    NORMAL: {
      id: 'normal',
      label: tr('Normal')
    },
    REAL_TIME: {
      id: 'real_time',
      label: tr('Real Time')
    },
  }

  const updateMgmtMode = useMutation((mode) => {
    console.log(garden.data);
    return axios.post(process.env.REACT_APP_PLANNER_API_END_POINT + "/garden/mgmt", {
      gardenId: garden.data.data.garden.id,
      mode: mode.id
    })
  }, {
    onSuccess: (
      data,
      variables
    ) => {
      notification.success({
        message: variables.label + ' ' + tr('mode activated')
      })
      queryClient.invalidateQueries('Garden')
    },
    onError: (
      e, variables
    ) => {
      notification.error({
        message: variables.label + ' ' + tr('mode activation failed'),
        description: e.toString()
      })
    }
  })

  const getActiveClassForMode = (mode) => {
    if (!garden.data.data.garden.mgmt) return;

    const managementMode = garden.data.data.garden.mgmt;
    if (managementMode === mode) {
      return 'bg-blue-500 text-white'
    }
  }

  const optimize = useMutation((optimization) => {
    return axios.post(process.env.REACT_APP_PLANNER_API_END_POINT + "/garden/optimize", {
      gardenId: garden.data.data.garden.id,
      optimize: {
        ...optimization
      }
    })
  }, {
    onSuccess: () => {
      notification.success({
        message: tr('Smart watering enabled')
      })
      queryClient.invalidateQueries('Garden')
    },
    onError: (e) => {
      notification.error({
        message: tr('Something went wrong while enabling smart watering'),
        description: e.toString()
      })
    }
  })

  const handleOptimization = (value) => {
    const isMobileScreen = width <= 768;
    if (isMobileScreen) {
      const ok = window.confirm(tr('Do you want to change the optimization settings?'))
      if (!ok) return;
    }

    console.log(value);
    optimize.mutate(value)
    setOptimization(p => ({
      ...p,
      ...value
    }))

  }

  const handleOptimizationMax = (key, value) => {
    setOptimization(p => ({ ...p, limits: { ...p.limits, max: { ...p.limits.max, ...{ [key]: value } } } }))
  }
  const updateOptimizationMax = (key, value) => {
    optimize.mutate({ ['max' + key.charAt(0).toUpperCase() + key.slice(1)]: value })
  }

  const dailyWateringReport = useQuery("DailyWateringReport", () =>
    axios.get(
      process.env.REACT_APP_PLANNER_API_END_POINT +
      "/garden/" +
      gardenId +
      "/watering-daily"
    )
  );

  const getReportByDevice = (id) => {
    if (!dailyWateringReport.isSuccess) return

    const [report] = dailyWateringReport.data.data.report.filter(r => r.device === id)
    if (!report) return;

    return report
  }

  const comparison = (id) => {
    const { waterings: [a, b] } = getReportByDevice(id)
    const isTrendingUp = (b >= a)

    return {
      isTrendingUp,
      value: b - a
    }
  }

  // complex templates
  const [startTimes, setStarttimes] = useState([])
  const [tempStartTimeDay, setTempStartTimeDay] = useState(null)
  const [tempStartTimeHour, setTempStartTimeHour] = useState(null)
  const [tempStartTimeMinute, setTempStartTimeMinute] = useState(null)
  const pushStartTimes = (startTime) => {
    if (!startTime.day || !startTime.hour || !startTime.minute) return;

    const duplicate = startTimes.find(s => s.day === startTime.day && s.hour === startTime.hour && s.minute === startTime.minute)
    if (duplicate) return;

    setStarttimes(p => [...p, startTime])

  }

  const EVERY_DAY = {
    id: 8,
    name: 'Her gün'
  }

  const handleAddStartTime = () => {
    // handle add every day of the week
    if (parseInt(tempStartTimeDay) === EVERY_DAY.id) {
      for (const d of ['1', '2', '3', '4', '5', '6', '0']) {
        pushStartTimes({
          day: d,
          hour: tempStartTimeHour,
          minute: tempStartTimeMinute,
        })
      }
    } else {
      pushStartTimes({
        day: tempStartTimeDay,
        hour: tempStartTimeHour,
        minute: tempStartTimeMinute,
      })
    }

  }

  const [multipleTempDurations, setMultipleTempDurations] = useState([0, 0, 0, 0, 0, 0, 0, 0])

  const hasWatering = (id) => {
    if (!garden.data || !garden.data.data.garden.next24h) return;

    const i = garden.data.data.garden.next24h.findIndex(d => d === id)

    return (i !== -1)
  }

  const [selectedDeviceSerial, setSelectedDeviceSerial] = useState(null)
  const [selectedDevice, setSelectedDevice] = useState(null)
  const [reportStartDate, setReportStartDate] = useState(moment().subtract(1, 'day').startOf('day'))
  const [reportEndDate, setReportEndDate] = useState(moment().add(1, 'day').endOf('day'))

  const deviceReport = useQuery(["DeviceReport", selectedDeviceSerial, reportStartDate.toString(), reportEndDate.toString()], () => {

    return axios.post(
      process.env.REACT_APP_PLANNER_API_END_POINT +
      "/garden/dashboard", {
      id: process.env.REACT_APP_DEVICE_WATERING_DASHBOARD,
      params: {
        device_id: selectedDeviceSerial,
        start_date: reportStartDate.format('YYYY-MM-DD'),
        end_date: reportEndDate.format('YYYY-MM-DD')
      }
    }
    )
  }, { enabled: !!selectedDeviceSerial, refetchOnWindowFocus: false });

  const [reportModalVisible, setReportModalVisible] = useState(false)

  const openReport = (device) => {
    console.log(device);
    setReportModalVisible(true)
    setSelectedDeviceSerial(device.deviceSerial)
    setSelectedDevice(device)
  }


  const onReportDateRangeChanged = ([start, end]) => {
    setReportStartDate(start)
    setReportEndDate(end)
  }

  const isCustomMultipleTemplatesActive = () => {
    // read hostname from url
    const hostname = window.location.hostname
    const ISTANBUL_HOSTNAME = 'istanbul.akillisula.com'
    if (hostname && hostname === ISTANBUL_HOSTNAME) return true;
  }

  const updateSchedulerCellDuration = useMutation((duration) => {
    return axios.put(
      process.env.REACT_APP_PLANNER_API_END_POINT +
      "/garden",
      {
        id: gardenId,
        schedulerCellDuration: duration,
      }
    )
  })

  const onCellDurationChanged = (e) => {
    setCellDuration(parseInt(e))
    updateSchedulerCellDuration.mutate(parseInt(e))
  }

  const updateDevicesOrder = useMutation((deviceIds) => {
    return axios.post(process.env.REACT_APP_PLANNER_API_END_POINT + "/garden/" + gardenId + "/devices-order",
      {
        deviceIds
      }
    )
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries("GardenDevices")
    }
  })

  const handleDragEnd = (result) => {
    if (!result.destination) return; // Return if dropped outside the list

    const updatedItems = [...gardenDevicesList];
    const [reorderedItem] = updatedItems.splice(result.source.index, 1);
    updatedItems.splice(result.destination.index, 0, reorderedItem);

    setGardenDevicesList(updatedItems);
    console.log(updatedItems.map(ud => ud._id));
    updateDevicesOrder.mutate(updatedItems.map(ud => ud._id))
  };

  const isConnectedToWaterMeter = (deviceId) => {
    const waterMeterDevices = gardenDevices.data.data.devices.filter(device =>
      device.waterMeter.status === 'active' &&
      device.waterMeter.connectedDevices &&
      device.waterMeter.connectedDevices.includes(deviceId)
    );
    return waterMeterDevices.length > 0;
  };


  return (
    <div className="container flex flex-col gap-4 p-4 bg-opacity-20 bg-white rounded-md rounded-tl-none">
      <div className="flex flex-row gap-4">
        <div className="w-full md:w-1/3 flex flex-col gap-2">
          <SchedulerGardenInformation
            notifications={notifications}
            navigate={navigate}
            mapCenter={mapCenter}
            gardenId={gardenId}
            gardenPic={gardenPic}
            showModal={showModal}
            isModalVisible={isModalVisible}
            handleOk={handleOk}
            handleCancel={handleCancel}
            gardenInfoTitle="Park/Bahçe Bilgisi"
          />

          <div className="flex flex-col gap-2 text-gray-600 h-full">
            <div className="flex gap-1 flex-wrap p-2 px-3 rounded-md bg-gray-50 border">
              <div className="w-full text-xs text-gray-700 ml-1 font-bold"> Akıllı Sula</div>
              <div className="flex gap-2 p-2 bg-white rounded-md border items-center text-gray-500 text-xs">
                {tr("Sun optimization")} <Switch checked={optimization.sun} onChange={(v) => { handleOptimization({ sun: v }) }} />
              </div>
              <div className="flex gap-2 p-2 bg-white rounded-md border items-center text-gray-500 text-xs">
                {tr("Rain optimization")} <Switch checked={optimization.rain} onChange={(v) => { handleOptimization({ rain: v }) }} />
              </div>
              <div className="flex gap-2 p-2 bg-white rounded-md border items-center text-gray-500 text-xs">
                {tr("Wind optimization")} <Switch checked={optimization.wind} onChange={(v) => { handleOptimization({ wind: v }) }} />
              </div>
              <div className="flex flex-col w-full p-1">
                {optimization.sun && <div>
                  <div className="text-xs text-gray-500 flex">{tr("Heat")} <div className="px-2 text-gray-700 font-bold">{optimization?.limits?.max?.sun || 0}°C</div></div>
                  <div className="px-2">
                    <Slider
                      min={20}
                      max={50}
                      onChange={(v) => handleOptimizationMax('sun', v)}
                      onAfterChange={(v) => updateOptimizationMax('sun', v)}
                      marks={{ 20: '20°C', 50: '50°C' }}
                      value={optimization?.limits?.max?.sun || 0}
                    />
                  </div>
                </div>}
                {optimization.rain && <div>
                  <div className="text-xs text-gray-500 flex">{tr("Rain probability")} <div className="px-2 text-gray-700 font-bold">
                    {/* {optimization?.limits?.max?.rain || 0}% */}
                  </div></div>
                  <div className="px-2">
                    <Slider
                      min={15}
                      max={60}
                      reverse={true}
                      onChange={(v) => handleOptimizationMax('rain', v)}
                      onAfterChange={(v) => updateOptimizationMax('rain', v)}
                      marks={{ 15: <span className=" font-bold text-lg text-gray-400">+</span>, 60: <span className=" font-bold text-lg text-gray-400">-</span> }}
                      value={optimization?.limits?.max?.rain || 0}
                      tooltipVisible={false}

                    />
                  </div>
                </div>}
                {optimization.wind && <div>
                  <div className="text-xs text-gray-500 flex">{tr("Wind speed")} <div className="px-2 text-gray-700 font-bold">{optimization?.limits?.max?.wind || 0}km/saat</div></div>
                  <div className="px-2">
                    <Slider
                      min={40}
                      max={100}
                      onChange={(v) => handleOptimizationMax('wind', v)}
                      onAfterChange={(v) => updateOptimizationMax('wind', v)}
                      marks={{ 40: { label: '40km/saat', style: { paddingLeft: 30 } }, 100: { label: '100km/saat', style: { paddingRight: 30 } } }}
                      value={optimization?.limits?.max?.wind || 0}

                    />
                  </div>
                </div>}

              </div>
            </div>
            <div className="bg-white rounded-md border  relative" >
              {garden.isSuccess && user && !['muratpasa'].includes(user.tenantId) && <div className={` p-2 px-4 border-b flex items-center justify-between gap-2 flex-wrap`}>
                <div className="flex flex-wrap text-xs items-center text-gray-500 bg-white rounded-md border overflow-hidden relative" >
                  {Object.keys(MGMT_MODES)
                    .map((modeKey) => <div
                      key={modeKey}
                      className={`p-2 py-1 hover:bg-gray-200 cursor-pointer ${getActiveClassForMode(MGMT_MODES[modeKey].id)}`}
                      onClick={(e) => updateMgmtMode.mutate(MGMT_MODES[modeKey])}>
                      {MGMT_MODES[modeKey].label}
                    </div>)}
                  {updateMgmtMode.isLoading && <div className=" absolute bg-white bg-opacity-70 flex items-center justify-center w-full h-full">
                    <BiLoaderAlt className=" animate-spin" />
                  </div>}
                </div>
              </div>}
              <DragDropContext onDragEnd={handleDragEnd}>
                <div className=" overflow-y-auto" style={{ height: 320 }}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div className="grid grid-cols-8" {...provided.droppableProps} ref={provided.innerRef}>
                        {/* <div className="px-4 py-1 pt-4 text-xs border-b text-gray-400 col-span-8">
                  {tr('Device Serial')}
                </div> */}
                        <div className="px-2 py-1 text-xs border-b text-gray-400 col-span-3">
                          {tr('Last online')}
                        </div>
                        <div className="px-2 py-1 text-xs border-b text-gray-400 col-span-3">
                          {tr('Watering')}
                        </div>
                        <div className="px-2 py-1 text-xs border-b text-gray-400 text-right col-span-1">
                          {tr('Battery')}
                        </div>
                        <div className="px-2 py-1 text-xs border-b text-gray-400 text-right col-span-1">
                          {tr('Signal')}
                        </div>
                        {gardenDevices.isSuccess &&
                          gardenDevicesList.map((d, i) => (
                            <Draggable key={d.deviceSerial} draggableId={d.deviceSerial} index={i}>
                              {(provided) => (
                                <div className="grid grid-cols-8 col-span-8"
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                >
                                  <div className="px-2 py-1 font-bold h-max flex items-center col-span-8 bg-gray-100 gap-1">
                                    <div><RxDragHandleDots2 /></div>
                                    {d.model === "BL" ? "" : getStatusIndicator(d.alive, d.mode)}
                                    {d.model === "BL" && <div className="text-blue-500 flex items-center font-normal"><BiBluetooth /> </div>}
                                    {hasWatering(d._id) && <div className="text-blue-500 "><BiDroplet />
                                    </div>}
                                    <NavLink to={'/control-center/users/devices/' + d._id} className=" text-gray-700">
                                      <div className="hover:text-blue-500 cursor-pointer flex items-center text-xs">{d.Device.name}
                                        <div className="flex items-center text-xs text-gray-400">
                                          <RightOutlined className=" " /></div>
                                      </div>
                                    </NavLink>
                                    {process.env.REACT_APP_DEVICE_WATERING_DASHBOARD !== "0" && !(d.deviceSerial.startsWith("BLT") && !isConnectedToWaterMeter(d._id)) && <>
                                      <Tooltip title="Sulama Raporu">
                                        <div className="flex content-center font-normal bg-blue-100 p-1 rounded-md cursor-pointer" onClick={() => openReport(d)}>
                                          <HiOutlineDocumentReport />
                                          <div className=" text-xs">
                                            Raporlar
                                          </div>
                                        </div>
                                      </Tooltip>
                                      <Modal footer={null}
                                        title={`Sulama Raporu [${(selectedDevice && selectedDevice.name) || ''
                                          } ${(selectedDevice && selectedDevice.sid && '-') || ''
                                          } ${(selectedDevice && selectedDevice.sid) || ''}]`}
                                        style={{ top: 0 }} width={"80vw"} height={"100vh"} visible={reportModalVisible} onOk={() => { setReportModalVisible(false) }} onCancel={() => { setReportModalVisible(false) }}>
                                        {deviceReport.isLoading && "Loading..."}
                                        <RangePicker defaultValue={[reportStartDate, reportEndDate]} onChange={onReportDateRangeChanged} />
                                        {/* {deviceReport.isSuccess && JSON.stringify(deviceReport.data)} */}
                                        {deviceReport.isSuccess && <iframe
                                          title="Sulama Raporu"
                                          src={deviceReport.data.data.url}
                                          frameborder="0"
                                          width="100%"
                                          height="800"
                                          allowtransparency="true"
                                        ></iframe>}

                                      </Modal>
                                    </>}
                                  </div>
                                  {/* <div className="border-b col-span-2"></div> */}
                                  <div className="px-2 py-1 text-xs h-max border-b flex items-center col-span-3">
                                    {d.model !== 'BL' && d.lastOnlineAt && dayjs(d.lastOnlineAt).format('DD/MM HH:mm:ss')}
                                    {d.model === 'BL' && d.lastLoginAt &&
                                      <Tooltip placement="right" title={<div>
                                        <div>Son bağlantı {dayjs(d.lastLoginAt).format('DD/MM HH:mm')} tarihinde</div>
                                        <div>{d.lastLoginBy} tarafından</div>
                                      </div>}>
                                        <div className="flex gap-1 items-center">
                                          {dayjs(d.lastLoginAt).format('DD/MM HH:mm:ss')}
                                          <span className=" text-blue-500">
                                            <BiInfoCircle />
                                          </span>
                                        </div>
                                      </Tooltip>
                                    }
                                  </div>
                                  <div className="px-2 py-1 text-xs h-max border-b flex items-center col-span-3  font-bold">
                                    {dailyWateringReport.isSuccess && getReportByDevice(d._id) && <>
                                      {getReportByDevice(d._id).waterings[1] * getReportByDevice(d._id).tickValue}  {getReportByDevice(d._id).units}
                                      <div className={`text-xs ml-1 text-${comparison(d._id).isTrendingUp ? 'green' : 'red'}-600 flex items-center`}>
                                        {comparison(d._id).isTrendingUp ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                                        {comparison(d._id).value * getReportByDevice(d._id).tickValue}  {getReportByDevice(d._id).units}
                                      </div>
                                    </>}
                                  </div>
                                  <div className="px-2 py-1 text-xs h-max border-b flex items-center col-span-1 justify-end">
                                    {calculateBatteryLevel(d.battery, d.batteryOffset, d.model)}
                                  </div>
                                  <div className="px-2 py-1 text-xs h-max border-b flex items-center col-span-1 justify-end">
                                    {d.model !== 'BL' && <SignalStrengthBars value={getSignalStrengthForBars(d.signal)} />}
                                  </div>
                                </div>)}
                            </Draggable>
                          ))}
                      </div>)}
                  </Droppable>
                </div>
              </DragDropContext>
            </div>
          </div>
        </div>
        {width > 768 && mapCenter && (
          <SchedulerMap
            mapCenter={mapCenter}
            baseMapUrl={tenantSettings?.defaultBaseMap?.value || BASE_MAPS[2].value}
            attribution={MAP_ATTRIBUTION_URL}
            mapZoom={8}
            pins={pins}
          />
        )}
      </div>
      {/* Scheduler start */}
      <div className="flex flex-col gap-4 text-gray-600">
        <div className="w-full border-t pt-4 text-gray-700 flex gap-4 items-end md:items-center flex-wrap">
          <div className="flex flex-row p-4 rounded-md bg-white border items-center text-gray-500 gap-4">
            <InfoLabel>Sulama</InfoLabel>
            <div>
              <Switch
                checked={isWateringStatusActive}
                onChange={() => { updateWateringStatus(!isWateringStatusActive) }}
                // size="small"
                checkedChildren="Aktif"
                unCheckedChildren="Pasif"
              />
            </div>
          </div>
          <div className="flex flex-row gap-2 p-4 bg-white rounded-md border items-center text-gray-500 relative">
            <div className="text-xs hidden sm:block">{tr("Quick")}</div>
            <Popconfirm title={<div><div className=" text-red-500">{tr('Will remove stored quick plan information')} </div> <br />{tr('Are you sure?')}</div>}
              okText={tr("Yes")}
              cancelText={tr("No")} okType="ghost"
              visible={isSwitchingToAdvanced}
              onCancel={() => { setIsSwitchingToAdvanced(false) }}
              overlayClassName=" rounded-md"
              onConfirm={onAdvancedConfirm}
            >
              <Popconfirm title={<div><div className=" text-red-500">{tr('Will remove stored advanced plan information')} </div> <br />{tr('Are you sure?')}</div>}
                okText={tr("Yes")}
                cancelText={tr("No")} okType="ghost"
                visible={isSwitchingToQuick}
                onCancel={() => { setIsSwitchingToQuick(false) }}
                overlayClassName=" rounded-md"
                onConfirm={onQuickConfirm}
              >
                <Switch
                  checked={isAdvancedFeaturesVisible()}
                  onChange={onSchedulerPrimaryModeChange}
                  checkedChildren={tr("Advanced")}
                  unCheckedChildren={tr("Quick")}
                />
              </Popconfirm>
            </Popconfirm>

            <div className="text-xs hidden sm:block">{tr("Advanced")}</div>
            {(isAdvancedFeaturesVisible()) && <><div className="ml-2   mr-2 flex items-center text-gray-300"><RightOutlined /></div>
              <div className="text-xs hidden sm:block">{tr("Common")}</div>
              <Popconfirm title={<div><div className=" text-red-500">{tr('Will remove stored per day information')} </div> <br />{tr('Are you sure?')}</div>}
                okText={tr("Yes")}
                cancelText={tr("No")} okType="ghost"
                visible={isSwitchingToAdvancedCommon}
                onCancel={() => { setIsSwitchingToAdvancedCommon(false) }}
                overlayClassName=" rounded-md"
                onConfirm={onAdvancedCommonConfirm}
              >
                <Switch
                  checked={schedulerMode === schedulerModes.ADVANCED_PER_DAY}
                  onChange={onSchedulerSecondaryModeChange}
                  checkedChildren={tr("Per day")}
                  unCheckedChildren={tr("Common")}
                />
              </Popconfirm>
              <div className="text-xs hidden sm:block">{tr("Per day")}</div></>}
            {!isWateringStatusActive && <div className=" absolute bg-white opacity-70 top-0 left-0 w-full h-full"></div>}
          </div>

          {(hasUnsavedChanges) && (
            <div className="flex gap-4 p-4 bg-white rounded-md border items-center border-green-brand">
              <div>{tr('There are unsaved changes to the watering schedule')}</div>
              <Button
                size="small"
                type="primary"
                style={{ borderRadius: 5 }}
                disabled={updateActiveTemplates.isLoading}
                onClick={handleSaveChanges}
              >
                {tr("Save")}
              </Button>
            </div>
          )}
          {hasUnsavedSchedulerChanges() && (
            <div className="flex gap-4 p-4 bg-white rounded-md border items-center border-green-brand">
              <div>{tr('There are unsaved changes to the watering schedule')}</div>
              <Button
                size="small"
                type="primary"
                style={{ borderRadius: 5 }}
                onClick={handleSaveScheduleChanges}
              >
                {tr("Save")}
              </Button>
            </div>
          )}
        </div>
        {!isAdvancedFeaturesVisible() && (
          <div className="flex flex-col gap-4 border-t pt-4">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={updateActiveTemplates.isLoading}>
              <div className=" w-auto md:w-full flex flex-col gap-4">
                <div>
                  <span className="text-xs flex flex-row items-center gap-1 ">
                    {tr('Select from quick plan templates or create your own.')}
                  </span>
                </div>


                <div className="flex gap-4 flex-wrap">
                  {/* start plan template */}
                  {templates
                    .filter((t) => t.type === "default")
                    .map((t) => generateTemplateCard(t))}
                  {/* end plan template */}
                </div>
                <div className="w-full flex flex-col gap-4">
                  <div className="flex gap-4 flex-wrap">
                    {/* start plan template */}
                    {templates
                      .filter((t) => t.type === "custom")
                      .map((t) => generateTemplateCard(t))}
                    {/* end plan template */}
                  </div>
                </div>
                <div className=" rounded-m flex flex-col gap-4">
                  <div>
                    <span className="block font-bold ">
                      {tr('Create quick plan templates')}
                    </span>

                    <InfoLabel>{tr('Each zone will be watered for selected number of minutes on selected days')}</InfoLabel>
                  </div>
                  <div className="p-4 border bg-white rounded-md w-auto md:w-max flex-wrap">
                    <div className="flex gap-3 flex-wrap">
                      <div className="flex flex-col">
                        <div className="text-xs p-1">{tr('Start at')}</div>
                        <div className="flex gap-1">
                          <Select placeholder={tr("hour")} onChange={setTemplateHours}>
                            {generateHoursArray().map(h => <Select.Option key={h}>{h}</Select.Option>)}
                          </Select>
                          <Select
                            placeholder={tr("minute")}
                            onChange={setTemplateMinutes}
                          >
                            {generateMinutesArray().map(m => <Select.Option key={m}>{m}</Select.Option>)}
                          </Select>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="text-xs p-1">{tr('Water for')}</div>
                        <Input
                          type="number"
                          min={1}
                          style={{ width: 100 }}
                          suffix={<span className="text-xs">mins</span>}
                          onChange={(e) => {
                            setTemplateDuration(e.target.value);
                          }}
                        />
                      </div>
                      <div className="flex flex-col w-full md:w-auto">
                        <div className="text-xs p-1">{tr('Active days')}</div>
                        <ActiveDaySelection
                          onChange={setTemplateActiveDays}
                          selectedDays={templateActiveDays}
                        />
                      </div>
                      {hasAccess(ROLES.TECHNICAL_ADMIN, role) && <div className="flex flex-col w-full md:w-auto items-center">
                        <div className="text-xs p-1">{tr('Global')}</div>
                        <Checkbox checked={isDefaultTemplate} onChange={(e) => { setIsDefaultTemplate(e.target.checked) }}>
                        </Checkbox>
                      </div>
                      }
                      <div className="flex items-end">
                        <Button
                          style={{ borderRadius: 5 }}
                          onClick={onTemplateCreate}
                        >
                          {tr('Create')}
                        </Button>
                      </div>
                    </div>
                  </div>


                </div>
                {isCustomMultipleTemplatesActive() && <div className="flex gap-4 flex-wrap">
                  {/* start plan template */}
                  {templates
                    .filter((t) => t.type === "custom_multiple")
                    .map((t) => <div
                      onClick={() => onTemplateClick(t)}
                      className={`flex flex-col bg-white border rounded-md relative ${getActiveTemplateClass(
                        t
                      )}`}>
                      {isActiveTemplate(t) && <div className="absolute top-2 left-2 flex rounded-full bg-green-brand w-5 h-5 items-center justify-center text-white border border-green-brand">
                        <CheckOutlined />
                      </div>}
                      {t.type === 'custom_multiple' && <div onClick={(e) => onTemplateDelete(e, t)} className="absolute top-1 right-1 flex items-center justify-center w-5 h-5 rounded-sm bg-gray-200 text-xs hover:bg-gray-500 hover:text-white"><DeleteOutlined /></div>}
                      <div className="text-xs pt-8 px-4">{tr('Water for')}</div>
                      <div className="flex pb-2 px-2">{t.durations.map((d, i) => <div className="flex items-center">{i !== 0 && <div>-</div>}<div className="px-2 font-bold text-lg">{d}</div></div>)}</div>
                      <div className="text-xs px-4 pt-2 border-t">{tr('Active days')}/{tr('Start at')}</div>
                      <div className="flex p-2 pt-0 gap-1">
                        {[1, 2, 3, 4, 5, 6, 0].map(day => <div className="flex flex-col p-2 ">
                          <div className={`${t.startTimes.filter(s => parseInt(s.day) === day).length > 0 ? 'text-green-brand' : 'text-gray-500'} text-lg font-bold`}>{days.find(d => d.id === day).name}</div>
                          <div className="flex flex-col">
                            {t.startTimes.filter(s => parseInt(s.day) === day).sort((a, b) => (parseInt(a.hour) * 60 + parseInt(a.minute)) - (parseInt(b.hour) * 60 + parseInt(b.minute))).map(s => <div> {s.hour}:{s.minute}</div>)}
                          </div>
                        </div>)}
                      </div>
                    </div>)}
                  {/* end plan template */}
                </div>}
                {isCustomMultipleTemplatesActive() && <div className="p-4 border rounded-md bg-gray-50 flex flex-col gap-4">
                  <div>
                    <span className="block font-bold ">
                      {tr('Tekrar eden hızlı plan oluşturun.')}
                    </span>

                    <InfoLabel> {tr('Each zone will be watered for selected number of minutes on selected days')}</InfoLabel>
                  </div>
                  <div className="p-4 border bg-white rounded-md w-auto flex-wrap">
                    <div className="flex flex-col gap-4 flex-wrap">
                      <div className="flex flex-col md:w-auto">
                        <div className="text-xs p-1">{tr('Water for')}</div>
                        {multipleTempDurations && <div className="flex gap-1 flex-wrap">{[0, 1, 2, 3, 4, 5, 6, 7].map(v =>
                          <div className="flex">
                            <div className=" rounded-l-md bg-gray-200 p-2 text-xs">{v + 1}</div>
                            <InputNumber
                              value={multipleTempDurations[v]}
                              style={{ width: 45 }} min={0}
                              onChange={(n) => { setMultipleTempDurations(p => p.map((a, i) => (i === v) ? n : a)) }} />
                          </div>
                        )}
                        </div>}
                      </div>
                      {startTimes.length > 0 && <div className="flex gap-4 p-2 border rounded-md relative">
                        {<div onClick={() => { setStarttimes([]) }} className="absolute top-1 right-1 flex items-center justify-center w-5 h-5 rounded-sm bg-gray-200 text-xs hover:bg-gray-500 hover:text-white"><CloseOutlined /></div>}
                        <div className="flex gap-1 flex-wrap">
                          {startTimes.map(s => <div className="p-2 bg-gray-100 rounded-md">
                            <div className="font-bold">{week.find(d => d.id === parseInt(s.day)).day}</div>
                            <div>{s.hour}:{s.minute}</div>
                          </div>)}
                        </div>

                      </div>}

                      <div className="flex flex-col">
                        <div className="text-xs p-1">{tr('Start at')}</div>
                        <div className="flex gap-1 flex-wrap">
                          <div>
                            <Select placeholder={tr("day")} style={{ width: 100 }} onChange={setTempStartTimeDay}>
                              <Select.Option key={EVERY_DAY.id}>{EVERY_DAY.name}</Select.Option>
                              {days.map(d => <Select.Option key={d.id}>{d.name}</Select.Option>)}
                            </Select>
                            <Select placeholder={tr("hour")} onChange={setTempStartTimeHour}>
                              {generateHoursArray().map(h => <Select.Option key={h}>{h}</Select.Option>)}
                            </Select>
                            <Select
                              placeholder={tr("minute")}
                              onChange={setTempStartTimeMinute}
                              style={{ marginRight: 5 }}
                            >
                              {generateMinutesArray().map(m => <Select.Option key={m}>{m}</Select.Option>)}
                            </Select>
                          </div>

                          <Button
                            style={{ borderRadius: 5 }}
                            onClick={handleAddStartTime}
                          >
                            {tr('Add time')}
                          </Button>
                        </div>
                      </div>

                      <div className="flex items-end">
                        <Button
                          style={{ borderRadius: 5 }}
                          onClick={(e) => onTemplateCreate(e, 'custom_multiple')}
                        >
                          {tr('Create')}
                        </Button>
                      </div>
                    </div>
                  </div>


                </div>}
              </div>
            </Spin>

          </div>
        )}
        {isAdvancedFeaturesVisible() && (
          <div>
            <div className="flex justify-between">
              <div className="flex flex-row gap-1 w-full">
                {schedulerMode === schedulerModes.ADVANCED_COMMON && <div className="px-4 py-2 bg-white border rounded-md rounded-b-none border-b-0  bg-white flex items-center">
                  <div className="flex flex-col gap-1 relative">
                    <div className=" text-xs">Active days</div>
                    <ActiveDaySelection
                      onChange={onCommonSchedulerActiveDaysChange}
                      selectedDays={selectedDays}
                    />
                    {(!isWateringStatusActive || plans.isFetching) && <div className=" absolute bg-white opacity-70 top-0 left-0 w-full h-full"></div>}

                  </div>
                </div>}
                {schedulerMode === schedulerModes.ADVANCED_PER_DAY && week.map((d) => (
                  <div
                    onClick={() => onWeekDaySelect(d.id)}
                    className={`tab-handle-scheduler w-full ${selectedDay === d.id ? "active" : ""
                      }`}
                  >
                    <div>
                      {d.day}
                      <span className="block text-xs text-gray-500">
                        {d.day}
                      </span>
                    </div>
                    <div>
                      <CheckSpot checked={selectedDays.includes(d.id)} onChange={(e) => onPerDaySchedulerActiveDaysChange(e, d.id)} />
                    </div>
                    {(!isWateringStatusActive || plans.isFetching) && <div className=" absolute bg-white opacity-70 top-0 left-0 w-full h-full"></div>}
                  </div>

                ))}
              </div>
              <div></div>
            </div>

            <div className="w-full rounded-md overflow-hidden relative">
              {gardenDevices.isSuccess && plans.isSuccess && <EventScheduler
                {...config}
                events={events}
                cellDuration={cellDuration}
                resources={resources}
                allowEventOverlap={false}
              />}
              {/* <DayPilotScheduler
                {...config}
                events={events}
                resources={resources}
                allowEventOverlap={false}
              /> */}
              {plans.isFetching && <div className=" absolute w-full h-full bg-white opacity-70 top-0 right-0 flex items-center justify-center z-10">
                <div className="flex flex-col items-center gap-3">
                  <div className="flex items-center justify-center text-3xl animate-spin">

                    <SyncOutlined />
                  </div>
                  {tr('Loading schedule data')}...
                </div>
              </div>}
              {(((schedulerMode === schedulerModes.ADVANCED_PER_DAY) && !selectedDays.includes(selectedDay)) || !isWateringStatusActive) && <div className=" absolute bg-white opacity-70 top-0 left-0 w-full h-full"></div>}

              <div className="flex absolute top-16 right-1 items-center gap-2">
                <div className="text-xs text-gray-400">{tr('Zoom level')}</div>
                <Select suffixIcon={<ZoomInOutlined />} size="small" value={cellDuration} onChange={onCellDurationChanged} style={{ width: 60 }}>
                  <Select.Option key="1">1m</Select.Option>
                  <Select.Option key="2">2m</Select.Option>
                  <Select.Option key="5">5m</Select.Option>
                  <Select.Option key="10">10m</Select.Option>
                  <Select.Option key="15">15m</Select.Option>
                  <Select.Option key="30">30m</Select.Option>
                </Select>
              </div>
            </div>

          </div>
        )}
      </div>
      {/* Scheduler end */}


      <div className="flex flex-col gap-2 text-gray-800 text-xs mt-8 border-t pt-4">
        <h3
          className="text-gray-600 text-sm cursor-pointer flex items-center"
          onClick={() => setIsWateringFlowEventsExpanded(!isWateringFlowEventsExpanded)}
        >
          Sulama Akış Olayları
          {isWateringFlowEventsExpanded ? <ArrowUpOutlined className="ml-2" /> : <ArrowDownOutlined className="ml-2" />}
        </h3>
        {isWateringFlowEventsExpanded && (
          <>
            {wateringFlowEvents.isLoading && <div>Loading watering flow events...</div>}
            {wateringFlowEvents.isError && <div>Error loading watering flow events</div>}
            {wateringFlowEvents.isSuccess && Object.keys(wateringFlowEvents.data.data.data).length > 0 && (
              <div className="bg-white rounded-md border p-2 text-xs opacity-70">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">Cihaz Seri Numarası</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">Bölge</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">Süre (dakika)</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">Akış</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">Akış Birimi</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">Başlangıç Zamanı</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">Bitiş Zamanı</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {Object.entries(wateringFlowEvents.data.data.data).map(([deviceSerial, deviceData]) => (
                      Object.entries(deviceData.result).map(([valve, events]) => (
                        events.map((event, index) => (
                          <tr key={`${deviceSerial}-${valve}-${index}`}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">{deviceSerial}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">{valve}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">{event.duration}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">{event.flow}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">{event.flowUnits || 'L/min'}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">{event.start.slice(0, -6).replace('T', ' ')}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">{event.end.slice(0, -6).replace('T', ' ')}</td>
                          </tr>
                        ))
                      ))
                    ))}
                  </tbody>
                </table>
                <div className="mt-4">
                  <span className="font-semibold">Sorgu Aralığı (UTC): </span>
                  <span>{wateringFlowEvents.data.data.queryRange.startTime.slice(0, -1).replace('T', ' ')} - </span>
                  <span>{wateringFlowEvents.data.data.queryRange.endTime.slice(0, -1).replace('T', ' ')}</span>
                </div>
              </div>
            )}
          </>
        )}
      </div>

    </div >
  );
};

export default Scheduler;
